/* mixins */

=mobile
  @media only screen and (max-width: 1000px)
    @content

=desktop
  @media only screen and (min-width: 1000px)
    @content

=dark
  @at-root html[data-theme="dark"] &
    @content

=status($status)
  @at-root html[data-status="#{$status}"] &
    @content

=dark-status($status)
  @at-root html[data-theme="dark"][data-status="#{$status}"] &
    @content

=font
  font-family: 'Inter', 'SF Pro Display', 'SF Pro Text', 'Arial', sans-serif

=invert
  filter: invert(1)

=smooth($properties...)
  $transition: ()
  @each $property in $properties
    $transition: append($transition, ($property 400ms ease), $separator: comma)
  @if length($properties)
    transition: $transition
  @else
    transition: all 400ms ease

=smooth-hover($prop: all)
  &:not(:disabled)
    transition: $prop 400ms ease

  &:not(:disabled):hover
    transition: $prop 60ms ease

=placeholder
  &::placeholder
    @content
  &:-ms-input-placeholder
    @content
  &::-ms-input-placeholder
    @content

=input-ellipsis
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap

=hover-colors
  &:hover
    background-color: #11121514

  &:active
    background-color: #11121529

  +dark
    &:hover
      background-color: #FFFFFF14

    &:active
      background-color: #FFFFFF29

=link-hover
  +smooth-hover(opacity)

  &:link
    opacity: 0.5

  &:hover
    opacity: 1

  &:active
    opacity: 0.8

=link-hover-pseudobutton
  background-color: transparent
  +smooth-hover(all)

  &:link
    opacity: 0.5

  &:hover
    opacity: 1

  &:active
    opacity: 1
    background-color: rgba(17, 18, 21, 0.16)

    +dark
      background-color: rgba(255, 255, 255, 0.08)

=selection
  ::-moz-selection
    @content
  ::selection
    @content

=disabled
  opacity: 0.35
  filter: grayscale(1)
  cursor: not-allowed



/* elements */

*
  box-sizing: border-box

+selection
  background: #22D066
  color: #fff

  +dark
    color: #111215

p
  padding: 0
  margin: 0

a,
a:hover,
a:focus,
a:active
  cursor: pointer
  text-decoration: none
  color: inherit

.disabled
  //+disabled

button
  line-height: 1em
  letter-spacing: -0.02em
  //color: #FFFFFF
  cursor: pointer
  +font

  //+dark
  //  color: #111215

  &[disabled]
    +disabled

input
  color: #111215
  +font

  +placeholder
    color: #11121580

  +dark
    color: #FFFFFF

    +placeholder
      color: #FFFFFF80



/* layout */

html
  height: 100%


html,
body
  margin: 0
  padding: 0
  width: auto
  height: 100%
  min-width: 375px // >=iPhone6


body
  height: 100%
  margin: 0
  min-width: 320px
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  background-color: #1112150A
  color: #111215
  font-size: 16px
  line-height: 20px
  +font
  +smooth(background-color, color)

  +dark
    background-color: #111215
    color: #FFFFFF

#root
  height: 100%
  +smooth(background-color)

  +status('success')
    background-color: rgba(34, 208, 102, 0.16)

  +dark-status('success')
    background-color: rgba(34, 208, 102, 0.08)

  +status('error')
    background-color: rgba(255, 9, 9, 0.16)

  +dark-status('error')
    background-color: rgba(255, 9, 9, 0.08)

#root > div:first-child
  min-height: 100%
  position: relative
  z-index: 0

#root > div.Toastify:empty
  display: none
  z-index: 1

.sides
  display: flex
  min-height: 100%
  position: relative

.sideLeft
  display: flex
  align-items: center
  justify-content: center
  width: 100%

  +mobile
    display: none

.sideRight
  display: flex
  flex-direction: column
  justify-content: space-between
  min-height: 100%
  width: 480px
  min-width: 480px
  background-color: #ffffff
  +smooth(background-color)

  +mobile
    width: 100%
    min-width: unset

  +dark
    background-color: rgba(255, 255, 255, 0.04)

  +status('success')
    //background: linear-gradient(0deg, rgba(34, 208, 102, 0.08), rgba(34, 208, 102, 0.08)), #FFFFFF
    background-color: #edfbf3

  +dark-status('success')
    //background: linear-gradient(0deg, rgba(34, 208, 102, 0.16), rgba(34, 208, 102, 0.16)), #111215
    background-color: #143022

  +status('error')
    //background: linear-gradient(0deg, rgba(255, 9, 9, 0.08), rgba(255, 9, 9, 0.08)), #FFFFFF
    background-color: #ffebeb

  +dark-status('error')
    //background: linear-gradient(0deg, rgba(255, 9, 9, 0.16), rgba(255, 9, 9, 0.16)), #111215
    background-color: #371113


.main
  width: 100%
  padding: 0 16px
  position: relative
  display: flex
  align-items: center
  flex-direction: column

footer
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  margin-top: 60px
  padding: 0 40px 40px
  font-size: 15px
  line-height: 19px

  +mobile
    padding: 0 24px 24px
    font-size: 13px

  a
    display: flex
    align-items: center
    +link-hover

    > * + *
      margin-left: 8px

  img
    display: block
    margin-left: 0.7em


.pageContent
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  padding-left: 5%
  padding-right: 5%

  .nftCard
    display: none

    +mobile
      display: block


@media only screen and (max-width: 1000px)
  .pageContent
    flex-direction: column
    padding-left: unset
    padding-right: unset

  .mainForm,
  .statusPanel
    margin-left: unset
    top: unset
    padding-left: 16px
    padding-right: 16px



/* All */

.info
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 22px


.offer
  display: flex
  align-items: center


.offerName
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 21px
  margin-left: 9px


.offerImagePlace
  display: flex
  align-items: center
  justify-content: center
  width: 40px
  height: 40px


.offerImage
  display: block
  max-width: 40px
  max-height: 40px


.offerPrice
  display: flex
  /*align-items: center*/
  flex-direction: column
  align-items: self-end
  margin-left: 1em


.offerPriceValueUnit
  font-weight: bold
  font-size: 24px
  line-height: 24px


.offerPriceValueUnit.prefixedUnit
  display: flex
  flex-direction: row-reverse


.offerPriceValueUnit.prefixedUnit .offerPriceSpace
  display: none


.period
  font-style: normal
  font-weight: normal
  font-size: 13px
  line-height: 13px

.input
  background: #FFFFFF
  border: 1px solid #D4D6DB
  border-radius: 12px
  padding: 12px
  outline: none
  height: 48px
  width: 100%
  font-style: normal
  font-weight: normal
  font-size: 18px
  line-height: 24px
  display: flex
  align-items: center
  margin-bottom: 20px


.input:focus
  background: linear-gradient(#fff,#fff) padding-box, linear-gradient(90deg, #2DF7FD 0%, #269EFF 51.37%, #7DABFF 97.73%) border-box
  border: 1px solid transparent
  border-radius:15px
  display: inline-block


.payControls
  margin-top: 12px
  margin-bottom: 8px
  border: 1px solid #11121529
  +smooth-hover

  +dark
    border-color: #FFFFFF29

  &.notCrosstype
    border-radius: 0
    border-left: none
    border-right: none
    border-bottom: none

  &.crosstype
    border-radius: 12px
    margin-bottom: 40px

    &:hover
      border-color: #11121566

    &.focused
      border-color: #22D066

    +dark
      border-color: #FFFFFF29

      &:hover
        border-color: #FFFFFF66

      &.focused
        border-color: #22D066


.coin
  outline: none
  height: 56px
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between

  &.crosstype
    padding-left: 12px

.coinSelectWrapper
  margin-right: 4px

.coinSelect
  display: flex
  align-items: center
  height: 48px
  padding: 0 12px

.coinSelectLoading
  padding-right: 12px

.coinSelectActive
  border-radius: 10px
  background-color: transparent
  +smooth-hover

  &:hover
    background-color: #11121514

    +dark
      background-color: #FFFFFF14

  &:active
    background-color: #11121529

    +dark
      background-color: #FFFFFF29


.flex
  display: flex
  align-items: center


.txFee
  display: flex
  align-items: center
  justify-content: space-between
  margin: 0 12px 24px


@media (min-width: 768px)
  .txFee
    margin: 0 12px 30px


.currentToken
  width: 24px
  height: 24px
  display: flex
  align-items: center
  justify-content: center


.currentTokenIcon
  max-width: 24px
  max-height: 24px


.tokenTitle
  font-style: normal
  font-weight: 600
  font-size: 20px
  line-height: 20px
  margin-left: 8px
  margin-right: 8px


.tokenSum
  font-weight: 700
  font-size: 20px
  line-height: 20px


.legendTitle
  font-style: normal
  font-weight: normal
  font-size: 15px
  line-height: 19px


.question
  font-style: normal
  font-weight: normal
  font-size: 15px
  line-height: 19px
  color: rgba(49, 114, 205, 0.5)
  margin-left: 10px


.legendLabel
  font-style: normal
  font-weight: normal
  font-size: 15px
  line-height: 19px
  color: rgba(42, 46, 51, 1)
  margin-left: 9px


.legendLabelLight
  font-style: normal
  font-weight: normal
  font-size: 15px
  line-height: 19px
  color: rgba(42, 46, 51, 0.6)
  margin-left: 9px

.crosstypeRecipientWrapper
  position: relative

.crosstypeNetwork
  position: absolute
  top: 17px
  left: 12px

  .addAddressIcon
    position: absolute
    right: -5px
    top: -5px

.crosstypeRecipientDivider
  margin: 0
  padding: 0
  border: none
  height: 1px
  margin-left: 12px
  margin-right: 12px
  background-color: #11121529

  +dark
    background-color: #FFFFFF29

input.crosstypeRecipient
  margin-top: -1px
  width: 100%
  height: 56px
  outline: none!important
  border: none
  background: transparent
  padding: 0 67px 0 40px
  color: #111215
  font-size: 16px
  line-height: 20px
  +input-ellipsis
  +smooth-hover

  +dark
    color: #FFFFFF

  &.error
    color: #FF0909!important

.paste
  position: absolute
  top: 50%
  transform: translateY(-50%)
  right: 12px
  width: 48px
  height: 24px
  border: none
  border-radius: 4px
  padding: 1px
  background: none
  background-color: transparent
  color: #11121580
  font-size: 12px
  line-height: 16px
  +smooth-hover

  +dark
    color: #FFFFFF80

  &:hover
    background-color: #11121514
    color: #111215

    +dark
      background-color: #FFFFFF14
      color: #FFFFFF

  &:active
    background-color: #11121529

    +dark
      background-color: #FFFFFF29


.addressError
  position: absolute
  bottom: -22px
  left: 14px
  font-size: 12px
  line-height: 16px
  color: #FF0909
  opacity: 0
  pointer-events: none
  +smooth-hover

  &.visible
    opacity: 1
    pointer-events: all

.walletLink
  position: absolute
  bottom: -22px
  left: 14px
  font-size: 12px
  line-height: 16px

  opacity: 0
  pointer-events: none
  +smooth-hover

  &.visible
    opacity: 1
    pointer-events: all

  a
    display: block
    +link-hover

    > * + *
      margin-left: 7px


.formFooter
  display: flex
  margin-top: 64px

  +mobile
    margin-top: 24px


.formFooter * + *
  margin-left: 11px


.quantity
  display: flex
  align-items: center
  width: 120px
  height: 48px
  border: 1px solid #11121529
  border-radius: 12px
  +smooth-hover

  +dark
    border: 1px solid #FFFFFF29

  &:hover
    border: 1px solid #11121566

    +dark
      border: 1px solid #FFFFFF66

.quantityButton
  margin: 3px
  width: 40px
  height: 40px
  cursor: pointer
  border-radius: 9px
  border: none
  background: none
  background-color: transparent
  font-size: 22px
  color: #2A2E33 // safari
  padding: 5px // safari
  user-select: none
  +smooth-hover

  +dark
    color: #FFFFFF

  &:not(:disabled)
    &:hover
      background-color: #11121514

      +dark
        background-color: #FFFFFF14

    &:active
      background-color: #11121529

      +dark
        background-color: #FFFFFF29


.quantityValue
  margin: 0 -4px
  width: 34px
  height: 46px
  font-weight: 400
  font-size: 20px
  line-height: 46px
  text-align: center
  background: none
  border: none
  outline: none!important


.submit
  position: relative
  width: 100%
  height: 48px
  outline: none
  border: none
  border-radius: 12px
  background: #22D066
  color: #FFFFFF
  font-style: normal
  font-weight: 600
  font-size: 20px
  text-align: center
  +smooth-hover

  +dark
    color: #111215

  &:not(:disabled):hover
    filter: brightness(1.1)

  &.success
    background-color: #51AA7F
    // overwrite [disabled]
    filter: unset
    opacity: unset

  .submitLoaderIcon
    position: absolute
    right: 16px
    -webkit-animation: rotating 2s linear infinite
    -moz-animation: rotating 2s linear infinite
    -ms-animation: rotating 2s linear infinite
    -o-animation: rotating 2s linear infinite
    animation: rotating 2s linear infinite

  .submitSuccessIcon
    position: absolute
    right: 16px


.wrongNetwork
  display: flex
  align-items: center
  justify-content: center
  height: 40px
  border-radius: 12px
  padding: 10px 9px 9px 9px
  background: #c93e35
  border: 2px solid #c93e35
  position: relative
  cursor: pointer


.wrongNetwork:hover
  border: 2px solid #8e2c25


.wrongNetwork .wrongNetworkInner
  display: flex
  align-items: center
  justify-content: center
  color: white
  font-size: 18px
  font-style: normal
  font-weight: 500
  line-height: 21px


.wrongNetwork img.wrongNetworkIcon
  margin-right: 9px
  position: relative
  top: -2px


.wrongNetwork .navSelect
  left: unset
  right: 0
  cursor: auto


.navButton
  margin-left: 10px
  display: flex
  justify-content: center
  align-items: center
  height: 40px
  width: 145px
  border: none
  border-radius: 12px
  outline: none
  background: #22D066
  padding-left: 6px
  padding-right: 6px
  font-style: normal
  font-weight: 500
  font-size: 16px
  text-align: center
  color: #FFFFFF
  cursor: pointer
  +smooth-hover

  &:hover
    filter: brightness(1.1)

  +dark
    color: #111215


@media only screen and (max-width: 420px)
  .navButton
    width: 160px

.navButton > img
  margin-right: 7px
  margin-bottom: 2px
  width: 19px
  height: 16px


.mainForm,
.statusPanel
  width: 100%
  max-width: 480px

  +desktop
    padding: 0


/* @keyframes openSelect
  from
    transform: scale(0) translate(0, 0)
  to
    transform: scale(1) translate(-50%, -50%) */

.close
  position: absolute
  top: 24px
  right: 24px
  width: 48px
  height: 48px
  border-radius: 16px
  background-color: transparent
  +hover-colors
  +smooth-hover

  +mobile
    top: 10px
    right: 10px

  img
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

    +dark
      +invert


.network
  display: flex
  align-items: center
  background: #fff
  border: 2px solid #D4D6DB
  border-radius: 15px
  padding: 9px 15px
  margin-top: 10px
  /* height: 50px */
  +smooth-hover

  &:hover
    background: rgba(42, 46, 51, 0.08)
    border: 2px solid #D4D6DB
    cursor: pointer


.choosenNetwork,
.choosenNetwork:hover
  background: linear-gradient(#fff,#fff) padding-box, linear-gradient(90deg, #2DF7FD 0%, #269EFF 51.37%, #7DABFF 97.73%) border-box
  border: 2px solid transparent


.networkDisabled
  border: 1px dashed #D4D6DB
  background: #fff
  opacity: 0.5


.networkDisabled:hover
  border: 1px dashed #D4D6DB
  background: #fff
  cursor: not-allowed


.networkIconName
  width: 50px
  display: flex
  flex-direction: column
  align-items: center
  text-align: center


.networkIconWrapper
  width: 24px
  height: 24px
  margin-bottom: 5px


.networkIcon
  display: block
  width: 24px
  height: 24px
  overflow: hidden


.networkName
  font-style: normal
  font-weight: bold
  font-size: 13px
  line-height: 14px
  text-align: center


.networkTxCost
  margin-left: 28px
  min-width: 92px
  text-align: left


.networkDescription
  font-style: normal
  font-weight: normal
  font-size: 12px
  line-height: 14px
  color: rgba(42, 46, 51, 0.6)


.networkSum
  font-style: normal
  font-weight: normal
  font-size: 15px
  line-height: 18px
  color: rgba(42, 46, 51, 0.8)


.walletItem
  display: flex
  flex-direction: column
  align-items: center
  //justify-content: center
  padding-top: 19px
  text-align: center
  width: 23%
  height: 96px
  border-radius: 12px
  border: 1px solid #D4D6DB
  background-color: transparent
  position: relative
  cursor: pointer
  +hover-colors
  +smooth-hover

  +mobile
    padding-top: 11px

  +dark
    border: 1px solid #FFFFFF66


.walletItem .walletIconWrapper
  width: 32px
  height: 32px


.walletItem .walletIconWrapper img
  max-height: 100%
  max-width: 100%


.walletItem .walletName
  max-width: 80%
  font-style: normal
  font-weight: 600
  font-size: 13px
  line-height: 12px
  text-align: center
  margin-top: 8px


.walletItem .soon
  border-radius: 8px 2px 8px 8px
  background-color: #2A2E33
  color: #fff
  width: 35px
  height: 16px
  font-style: normal
  font-weight: normal
  font-size: 10px
  line-height: 14px
  position: absolute
  bottom: -8px


.disabledWallet
  border: 1px dashed #D4D6DB
  cursor: not-allowed


.walletItem.disabledWallet > p
  opacity: 0.3


.walletItem.disabledWallet > img
  opacity: 0.3



/* card */

.cardBg
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(0, 0, 0, 0.3)
  backdrop-filter: blur(10px)
  z-index: 11111111
  overflow-y: auto
  /*transition: 0.5s backdrop-filter linear*/

.card
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  z-index: 10
  width: 420px
  max-width: 100%
  max-height: 640px
  max-height: 80%
  box-shadow: 0px 6px 40px rgba(145, 166, 192, 0.16)
  border-radius: 24px
  background-color: #FFFFFF
  padding: 40px
  overflow-y: auto

  +dark
    background-color: #111215

.cardHeader
  padding: 32px 60px 20px 40px


@media only screen and (max-width: 768px)
  .cardHeader
    padding: 20px 60px 10px 15px
    //border-bottom: 1px solid #E4E7ED


.cardHeader .cardTitle
  font-size: 20px
  font-weight: 700
  line-height: 32px


@media only screen and (max-width: 768px)
  .cardHeader .cardTitle
    font-size: 18px
    line-height: 24px


.cardMain
  padding: 16px 27px 32px 27px


@media only screen and (max-width: 768px)
  .cardMain
    padding: 16px 7px 16px 7px


.cardSelectToken
  padding: 0
  display: flex
  flex-direction: column
  min-height: 350px

  .cardMain
    overflow-y: auto
    -webkit-overflow-scrolling: touch

  .tokens
    position: relative


.cardSelectWallet
  width: 480px

  .selectWalletTitle
    font-style: normal
    font-weight: 700
    line-height: 24px
    font-size: 20px

  .wallets
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 40px
    margin-bottom: 10px


.cardConfirmTx
  +mobile
    max-width: 92%

  .cardMain
    min-height: 260px
    padding: 40px 0 0 0
    text-align: center

  .indicatorPlace
    margin-bottom: 40px
    display: flex
    justify-content: center

  .cardTitle
    margin-bottom: 20px
    font-size: 28px
    font-weight: 600
    line-height: 36px
    letter-spacing: -0.02em

  .payTxLink
    display: inline-flex
    align-items: center
    justify-content: center
    width: 180px
    height: 40px
    +link-hover

    > * + *
      margin-left: 8px

  .closeButton
    margin: 8px auto 0
    width: 180px
    height: 40px
    border: none
    border-radius: 12px
    outline: none
    background: #22D066
    padding-left: 6px
    padding-right: 6px
    font-style: normal
    font-weight: 500
    font-size: 16px
    text-align: center
    color: #FFFFFF
    +smooth-hover

    &:hover
      filter: brightness(1.1)

    +dark


.tokenName
  font-style: normal
  font-weight: 600
  font-size: 16px
  line-height: 16px


.tokenItem
  display: flex
  align-items: center
  justify-content: space-between
  border-radius: 8px
  background-color: transparent
  padding: 12px 13px 10px 13px
  cursor: pointer
  +hover-colors
  +smooth-hover

  .tokenIconWrapper
    width: 24px
    height: 24px

  .tokenIcon
    width: 100%
    height: 100%
    text-align: center
    object-fit: scale-down

  .tokenText
    margin-left: 7px
    margin-right: 7px

  .tokenTextTop
    //margin-bottom: 2px

  .tokenTextTop,
  .tokenTextBottom
    display: flex
    align-items: center

  .tokenNetworkName
    margin-top: 1px
    margin-left: 3px
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 16px

  .tokenValue
    font-size: 16px
    line-height: 19px
    text-align: right
    opacity: 0.5



.Header
  width: 100%
  display: flex
  align-items: center
  justify-content: right
  padding: 16px 16px 70px
  z-index: 10

  +mobile
    justify-content: space-between

  .headerSelect
    padding: 8px
    border-radius: 12px
    border: 1px solid transparent
    background-color: #fff
    display: flex
    align-items: center
    justify-content: space-between
    /* width: 160px */
    height: 23px
    position: relative
    cursor: pointer
    user-select: none
    +smooth-hover

    &:hover
      border: 1px solid #d9dcdf


.tokenSelectName
  font-style: normal
  font-weight: 500
  font-size: 18px
  line-height: 21px
  margin-left: 4px


.userInfo
  flex-wrap: wrap
  display: flex
  align-items: center


.authWallet
  display: flex
  flex-direction: row
  align-items: center
  justify-content: space-between
  border-radius: 12px
  height: 40px
  margin-left: 4px

.balance
  margin-left: 8px
  font-weight: 600
  font-size: 16px
  line-height: 20px


.addressContainer
  display: flex
  align-items: center
  position: relative
  height: 40px
  border: 1px solid rgba(17, 18, 21, 0.16)
  border-radius: 12px
  background-color: transparent
  padding: 0 10px 0 8px
  cursor: pointer
  user-select: none
  +smooth-hover

  +dark
    border: 1px solid rgba(255, 255, 255, 0.16)

  &:hover
    border: 1px solid rgba(17, 18, 21, 0.4)

    +dark
      border: 1px solid #FFFFFF66

  &:active
    background-color: rgba(17, 18, 21, 0.16)
    border: 1px solid rgba(17, 18, 21, 0.16)

    +dark
      background-color: rgba(255, 255, 255, 0.16)
      border: 1px solid rgba(255, 255, 255, 0.16)




.balanceContainer
  display: flex
  align-items: center

.address
  margin-left: 9px
  display: inline-block
  max-width: 200px
  font-size: 16px
  line-height: 16px
  color: #11121580
  overflow: hidden
  text-overflow: ellipsis

  +dark
    color: #FFFFFF80


.authWallet
  position: relative

.addressDropdown
  position: absolute
  top: 50px
  right: 0
  box-shadow: 3px 3px 12px 3px #11121511
  background-color: #FFFFFF
  border: 1px solid #11121529
  border-radius: 12px
  padding: 20px 24px 20px 20px

  +dark
    background-color: #111215
    border: 1px solid rgba(255, 255, 255, 0.16)

  .connected
    font-weight: 600
    letter-spacing: 0em
    //color: #11121580
    margin-bottom: 14px

  .accountAvatar
    position: relative
    width: 30px
    height: 30px

  .accountAvatarIcon
    max-width: 100%
    max-height: 100%

  .addressInfo
    margin-left: 12px
    margin-right: 15px
    text-align: left

  .addressValue
    font-style: normal
    font-weight: 500
    line-height: 16px
    opacity: 0.5

  .walletName
    margin-bottom: 3px
    font-style: normal
    font-weight: 500
    line-height: 16px

  .buttons
    display: flex

    & > * + *
      margin-left: 8px

  .button
    display: flex
    align-items: center
    justify-content: center
    width: 32px
    height: 32px
    border: 1px solid #11121529
    border-radius: 50%
    padding: 2px
    background: none
    +smooth-hover

    +dark
      border: 1px solid rgba(255, 255, 255, 0.16)

    &:not([disabled])
      cursor: pointer

    &[disabled]
      opacity: 0.4
      background-color: #eee
      cursor: not-allowed

    &:hover
      background: #8883
      transition: background-color 1ms ease

    img
      +dark
        +invert


.navSelect
  position: absolute
  top: 50px
  left: 0
  z-index: 2
  width: 252px
  background: #FFFFFF
  box-shadow: 0px 0px 40px 6px rgba(145, 166, 192, 0.16)
  border-radius: 8px
  padding: 12px
  padding-top: 0

.navSelectIcon
  width: 24px
  height: 24px
  overflow: hidden


.selectArrow
  width: 8px
  height: 5px
  margin-left: 10px

.tip
  width: 190px


.page404
  min-height: 100%
  display: flex
  flex-direction: column
  justify-content: space-between

  .Header
    margin-bottom: 60px

  footer
    justify-content: center

  .sheet
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 220px
    height: 300px
    background-image: url('./images/404-sheet.svg')
    background-repeat: no-repeat

    +dark
      background-image: url('./images/404-sheet-dark.svg')

  .title
    margin-bottom: 10px
    font-size: 28px
    font-weight: 600
    line-height: 36px
    letter-spacing: -0.02em

    +dark
      opacity: 0.8

  .subtitle
    font-family: Inter
    font-size: 16px
    font-weight: 400
    line-height: 20px
    opacity: 0.5

    +dark
      opacity: 0.4




@-webkit-keyframes rotating /* Safari and Chrome */
  from
    -webkit-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)
  to
    -webkit-transform: rotate(360deg)
    -o-transform: rotate(360deg)
    transform: rotate(360deg)

@keyframes rotating
  from
    -ms-transform: rotate(0deg)
    -moz-transform: rotate(0deg)
    -webkit-transform: rotate(0deg)
    -o-transform: rotate(0deg)
    transform: rotate(0deg)
  to
    -ms-transform: rotate(360deg)
    -moz-transform: rotate(360deg)
    -webkit-transform: rotate(360deg)
    -o-transform: rotate(360deg)
    transform: rotate(360deg)



.nftCard
  width: 480px
  max-width: 100%
  background-color: #FFFFFF
  box-shadow: 0px 6px 40px rgb(145 166 192 / 16%)
  border-radius: 24px
  overflow: hidden
  position: relative

  +dark
    background-color: rgba(255, 255, 255, 0.04)

  +mobile
    margin-bottom: 25px


.nftImageBox
  padding-bottom: 100% // aspect ratio 1:1
  position: relative
  overflow: hidden
  border-radius: 24px


.nftImageSkeleton,
.nftImageUnderlay,
.nftImage
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-position: center
  background-repeat: no-repeat


.nftImageUnderlay,
.nftImage
  opacity: 0
  /*transform: scale(1.07)*/
  animation: nftImageShow 1s
  animation-iteration-count: 1
  animation-fill-mode: forwards


@keyframes nftImageShow
  to
    opacity: 1
    transform: scale(1)


.nftImageUnderlay
  background-size: cover
  filter: blur(5px)


.nftImage
  background-size: contain


.merchantLogo
  display: flex
  align-items: center
  height: 40px
  width: 120px

  img
    display: block
    max-height: 40px
    max-width: 120px

    //+dark
    //  +invert

.Header .merchantLogo
  display: none

  +mobile
    display: flex

.mainForm .merchantLogo
  margin-bottom: 60px

  +mobile
    display: none


.nftMintedAndTitle

  .nftMinted,
  .nftTitle.soldOut
    font-size: 16px
    line-height: 20px
    font-weight: 400

  .nftTitle,
  .nftSoldOut
    margin-top: 4px
    font-weight: 600
    font-size: 28px
    line-height: 36px



.nftPrices
  position: absolute
  top: 8px
  left: 8px
  display: flex
  align-items: center
  background: rgba(255, 255, 255, 0.5)
  backdrop-filter: blur(60px)
  border-radius: 20px
  padding: 4px 8px 4px 4px


.nftPrice
  display: flex
  align-items: center
  justify-content: flex-end
  text-align: right


.nftPrice + .nftPrice
  margin-left: 16px


.nftPrice img
  display: block
  margin-right: 0.3em
  height: 1em
  width: 1em


.nftPriceMain
  font-weight: 700
  font-size: 20px
  line-height: 20px
  color: #111215

.nftPricesOther
  margin-left: 5px
  font-size: 16px
  line-height: 16px
  font-weight: 400
  color: #11121580


.statusPanel
  .statusText
    min-height: 128px
    margin-top: 31px

  .statusTitle
    font-size: 28px
    font-weight: 600
    line-height: 36px
    letter-spacing: -0.02em

  .statusErrorDescription
    margin-top: 8px
    font-size: 20px
    font-weight: 400
    line-height: 32px

  .statusTxLink
    display: inline-block
    margin-top: 16px
    font-size: 16px
    font-weight: 400
    line-height: 20px
    +link-hover

  .statusNftTitle
    margin-top: 8px
    font-size: 16px
    font-weight: 400
    line-height: 20px

  .statusTokenSum
    margin-top: 8px
    display: flex
    align-items: center
    font-size: 20px
    font-weight: 700
    line-height: 32px

  .statusFooter
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: 12px
    height: 66px
    border-top: 1px solid rgba(17, 18, 21, 0.12)
    padding: 8px 0

    +dark
      border-top: 1px solid rgba(255, 255, 255, 0.12)

    &:empty
      border-top: none!important

    .statusBack
      height: 48px
      padding: 14px 22px 14px 11px
      border-radius: 12px
      +link-hover-pseudobutton

      svg + span
        margin-left: 15px

    .statusSocials
      width: 48px
      height: 48px

    .twButton
      display: flex
      align-items: center
      justify-content: center
      width: 48px
      height: 48px
      border-radius: 12px
      +link-hover-pseudobutton


.Skeleton
  /*width: 100%*/
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0) 80%), rgb(243, 245, 249)
  background-repeat: repeat-y
  background-size: 30% 100%
  background-position: 0 0
  animation: SkeletonShine 1s infinite

@keyframes SkeletonShine
  to
    background-position: 130% 0, 0 0 // move highlight to right


.Copy
  position: relative

  .copied
    top: -37px
    position: absolute
    left: 50%
    transform: translate(-50%, 80%) scale(0)
    font-size: 12px
    font-weight: 500
    padding: 8px 18px
    background-color: #777
    border-radius: 7px
    color: white
    opacity: 0
    transition: all 200ms ease

    &.active
      opacity: 1
      transform: translate(-50%, 0%) scale(1)

    &::after
      content: ''
      display: table
      position: absolute
      top: 100%
      left: 50%
      transform: translateX(-50%)
      width: 0
      height: 0
      border-style: solid
      border-width: 5px 5px 0 5px
      border-color: #777 transparent transparent transparent


.Indicator
  width: 74px
  height: 36px
  display: flex
  align-items: center
  justify-content: center

  .stick
    width: 4px
    height: 100%
    background-color: #22D066
    border-radius: 2px
    max-height: 4px
    opacity: 0.24
    +smooth-hover

    @for $i from 1 through 8
      &:nth-child(#{$i})
        animation-delay: #{0.08 * $i}s

  .stick + .stick
    margin-left: 6px

  &.status-wait
    .stick
      max-height: 4px
      opacity: 0.24
      animation-name: pulse
      animation-duration: 2s
      animation-timing-function: ease-in-out
      animation-iteration-count: infinite

  &.status-error
    .stick
      background-color: #FF0909
      max-height: 36px
      opacity: 1

  &.status-success
    .stick
      max-height: 36px
      opacity: 1

@keyframes pulse
  0%
    max-height: 4px
    opacity: 0.24
  30%
    max-height: 36px
    opacity: 1
  60%
    max-height: 4px
    opacity: 0.24


.TimerButton
  position: relative
  z-index: 0
  overflow: hidden

  .progress
    z-index: -1
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    max-width: 0%
    background-color: rgba(17, 18, 21, 0.16)
    border-radius: 12px
    animation-name: progress
    animation-duration: 60s
    animation-timing-function: linear
    animation-iteration-count: 1
    animation-fill-mode: forwards

a.TimerButton .progress
  +dark
    background-color: rgba(255, 255, 255, 0.08)

@keyframes progress
  0%
    max-width: 0%
  100%
    max-width: 100%


.openDebug
  position: absolute
  bottom: 0
  right: 0
  width: 7px
  height: 7px
  cursor: copy

.Debug
  background: black
  color: white

  &,
  & button
    font-family: monospace

  .inner
    max-width: 300px
    margin-left: auto
    margin-right: auto
    padding: 10px
    font-size: 12px
    line-height: 1.1em



@media only screen and (max-width: 768px)
  .main
    /*overflow-y: auto*/
    /*overflow-x: hidden*/
    padding: 0 8px

  .Header
    padding: 16px 16px 51px

  .offerImagePlace
    display: flex
    align-items: center
    justify-content: center
    width: 34px
    height: 34px

  .offerImage
    display: block
    max-width: 34px
    max-height: 34px

  .card
    min-height: auto
    padding: 40px 15px

  .cardSelect
    overflow-y: auto
    max-height: 80%
    /*border-radius: 16px 16px*/
    border-radius: 16px 16px 0 0
    transform: translate(-50%, -0%)
    padding: 0
    width: 100%
    /*top: 10% !important*/
    top: 20% !important

  .cardSelectToken,
  .cardSelectWallet
    height: 100%

  .cardContent
    padding: 15px

  .offerPriceValueUnit
    font-size: 20px

  .walletItem
    /*width: 31%*/
    height: 84px

  .network
    justify-content: space-between
    padding: 15px
    height: 30px

  .networkIconName
    width: auto
    display: flex
    flex-direction: row
    align-items: center
    column-gap: 10px

  .networkIconWrapper
    margin-bottom: unset
    width: 30px
    height: 30px

  .networkIcon
    width: 30px
    height: 30px

  .networkName
    /*margin-left: 0.7em*/
    text-align: left

  .networkTxCost
    margin-left: 20px
    text-align: right

  .networkSum
    text-align: right

  .authWallet
    background: none
    padding: 0
    margin: 0

  .tokenSelectName
    display: none !important

  .Header .headerSelect
    width: auto

  .navSelect
    left: -92px // temp fix

  .navSelectIcon
    width: 20px
    height: 20px

  .address
    display: none
    padding-left: 12px
    padding-right: 12px
    height: 40px
    max-width: 160px
    font-size: 15px
    line-height: 40px

  .title
    margin-bottom: 20px

  .selectWalletTitle
    margin-bottom: 40px

  .tokenItem
    margin-left: 0px
    margin-right: 0px



@media only screen and (min-height: 701px) and (max-width: 768px)
  .cardContent
    margin-bottom: 35%


@media only screen and (max-height: 700px) and (max-width: 768px)
  .cardContent
    margin-bottom: 30%

  .cardSelectWallet,
  .cardSelectToken
    max-width: 500px


@media only screen and (max-height: 600px) and (max-width: 768px)
  .cardContent
    margin-bottom: 17%


@media screen and (max-device-width: 480px)
  .card
    min-width: 84%

@media only screen and (max-width: 500px)
  .Header
    padding: 16px 8px 16px
